import './reservationForm.css'
import { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom"
import { ReservationContext } from '../../context/ReservationContext';
import { AuthContext } from '../../context/AuthContext';
import requestUrl from '../../utils/requestMethods.js'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons'
import { format } from "date-fns";
import { SitesContext } from '../../context/SitesContext.js';
import { getSiteName } from '../../utils/helper.js';
import { ACTION_TYPES } from '../../utils/definitions.js';
import { useEffect } from 'react';

const ReservationForm = ({openReservationModal, selectedRoom, requestDetails, hoursDef}) => {
    const navigate = useNavigate();
    const { loading, error, dispatch } = useContext(ReservationContext);
    const { user } = useContext(AuthContext);
    const sitesCtx = useContext(SitesContext)
    let reservationStatus = 'SUBMITTED'
    if (user?.permissions.isAdmin) {
        reservationStatus = "APPROVED"
    }
    const startTime_PHT = new Date(Number(requestDetails.requestStartTime)).toLocaleString("en-US", {timeZone: "Asia/Manila"})
    const endTime_PHT = new Date(Number(requestDetails.requestEndTime)).toLocaleString("en-US", {timeZone: "Asia/Manila"})
    const [emailContents, setEmailContents] = useState({
        siteName: getSiteName(selectedRoom.siteId, sitesCtx),
        selectedRoom: selectedRoom
    });
    const [ reservation, setReservation ] = useState(
        {
            roomId: selectedRoom._id,
            requestor: user.domainId,
            domainId: user.domainId,
            siteId: selectedRoom.siteId,
            reservationStatus: reservationStatus,
            reservationStartTime: new Date(requestDetails.requestStartTime),
            reservationEndTime: new Date(requestDetails.requestEndTime),
            facilityType: requestDetails.search,
            duration: requestDetails.duration,
            pax: requestDetails.options.pax,
            purpose: requestDetails.search === 'sleeping' ? 'Rest' : '',
            occupants: requestDetails.search === 'sleeping' ? 'N/A' : '',
            position: user.position,
            shiftSchedule: "First",
            lineOfBusinessOrTower: user.tower,
            department: user.group,
            emailContents: emailContents,
            user: user
        }
    );
        
    const [isFormValidated, setIsFormValidated] = useState(false);
        
    const handleChange = (e) => {
        setReservation(prev => ({...prev, [e.target.id]: e.target.value }))
        if(reservation.purpose.trim() !== '' && reservation.occupants.trim() !== '') setIsFormValidated(true)
        else setIsFormValidated(false) 
    }

    const processReserve = async e => {
        e.preventDefault();
        setReservation(prev => ({...prev, emailContents: emailContents }))
        dispatch({type: ACTION_TYPES.START})
        try {
            let config = {
                headers: {
                  'Content-Type': 'application/json',
                },
                withCredentials: 'same-origin'
            }
            const res = await requestUrl.post(`/reservations?id=${user._id}`, reservation, config)
            dispatch({type: ACTION_TYPES.SUCCESS, payload: res.data})
            openReservationModal(false)
            navigate('/reservations')
        } catch (error) {
            dispatch({type: ACTION_TYPES.FAILURE, payload: error.response.data})
        }
    }

    useEffect(() => {
        // console.log('useEffect', reservation)
        // console.log('condition', (reservation.purpose.trim() !== '' && reservation.occupants.trim() !== ''))
        // console.log('loading', (loading))
        if(reservation.purpose.trim() !== '' && reservation.occupants.trim() !== '') setIsFormValidated(prev => true)
        else setIsFormValidated(prev => false) 
    }, [reservation])
    
    return (
        <div className="reservationModal">
            <div className="modalContainer">
                <FontAwesomeIcon 
                    icon={faCircleXmark}
                    className="closeModal"
                    onClick={ ()=> openReservationModal(false)}
                />
                <div className="modalTitle">
                    <h3>Reservation Form</h3>
                </div>
                <div className="modalForm">
                    <div className="modalFormColumn">
                        <div className="modalFormItem">
                            <label>Site Location</label>
                            <span>{getSiteName(selectedRoom.siteId, sitesCtx)}</span>
                        </div>
                        <div className="modalFormItem">
                            <label>Name of room</label>
                            <span>{selectedRoom.name}</span>
                        </div>
                        <div className="modalFormItem">
                            <label>Floor</label>
                            <span>{selectedRoom.floor}</span>
                        </div>                
                        <div className="modalFormItem">
                            <label>Capacity</label>
                            <span>{selectedRoom.capacity}</span>
                        </div>
                        <div className="modalFormItem">
                            <label>Requestor</label>
                            <span>{`${user.firstName} ${user.lastName}`}</span>
                        </div>
                        <div className="modalFormItem">
                            <label>Position</label>
                            <span>{user.position}</span>
                        </div>
                        <div className="modalFormItem">
                            <label>Date of Request</label>
                            <span>{`${format(new Date(startTime_PHT), "dd MMM yyyy")}`}</span>
                        </div>
                        <div className="modalFormItem">
                            <label>Start Time</label>
                            <span>{hoursDef[new Date(startTime_PHT).getHours()]}</span>
                        </div>
                        <div className="modalFormItem">
                            <label>End Time</label>
                            <span>{hoursDef[new Date(endTime_PHT).getHours()]}</span>
                        </div>
                        <div className="modalFormItem">
                            <label>Duration</label>
                            <span>{requestDetails.duration} hr/s</span>
                        </div>
                        <div className="modalFormItem">
                            <label>Pax</label>
                            <span>{requestDetails.options.pax}</span>
                        </div>
                    </div>
                    <div className="modalFormColumn">
                        <div className="modalFormItem">
                            <label>Purpose</label>
                            <input type="text" disabled={ requestDetails.search === 'sleeping' ? true : false }  className="reservationInput" autoFocus placeholder="Work" id="purpose" onChange={handleChange}/>
                        </div>
                        <div className="modalFormItem">
                            <label>Shift Schedule</label>
                            <select className="reservationSelect" id="shiftSchedule" defaultValue="First" onChange={handleChange}>
                                <option value="First">First Shift</option>
                                <option value="Mid">Mid Shift</option>
                                <option value="Night">Night Shift</option>
                            </select>
                        </div>
                        <div className="modalFormItem">
                            <label>Department</label>
                            <span>{user.group}</span>
                        </div>
                        <div className="modalFormItem">
                            <label>Tower</label>
                            <span>{user.tower}</span>
                        </div>
                        <div className="modalFormItem">
                            <label>Names of occupants</label>
                        </div>
                        <div className="modalFormItem" id="occupantNames">
                            <textarea name="" className="reservationTextArea" id="occupants" disabled={ requestDetails.search === 'sleeping' ? true : false } cols="30" rows="12" onKeyUp={handleChange} onChange={handleChange}></textarea>
                        </div>
                        <div className="modalFormItem">
                            <button disabled={(!isFormValidated && !loading) || (isFormValidated && loading)} className="reservationModalBtn" onClick={processReserve}>Submit Reservation</button>
                        </div>
                        { error && <span className="reservationError">{error.message}</span>}
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default ReservationForm
