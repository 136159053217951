import { useContext } from 'react';
import { SitesContext } from '../context/SitesContext.js'
import { PASSWORD_VALIDATION } from "./error";
import { authUrl } from "./authRequest";
import { GLOBAL, SPACE_TYPES_NAMES } from '../utils/definitions.js'
import { faDumbbell, faBed, faUsersBetweenLines, faPersonChalkboard, faComputer } from "@fortawesome/free-solid-svg-icons"
import moment from 'moment';

export const permissionsAdapter = (permissions) => {
    let permissionsObj = {
        isAdmin: false,
        isFacility: false,
        isWorkforce: false,
        isManager: false,
        isAssociate: false
    }
    permissions.forEach(element => {
        switch (element) {
            case 'admin':
                permissionsObj.isAdmin = true
                break;
            case 'facility':
                permissionsObj.isFacility = true
                break;
            case 'workforce':
                permissionsObj.isWorkforce = true
                break;
            case 'manager':
                permissionsObj.isManager = true
                break;
            case 'associate':
                permissionsObj.isAssociate = true
                break;
            default:
                break;
        }
    });
    return permissionsObj
}

export const numbersOnlyInput = (input) => {
    const onlyNumbersRegExp = /^\d+$/;
    return onlyNumbersRegExp.test(input)
}

export const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@.#$!%*?&^])[A-Za-z\d@.#$!%*?&]{8,15}$/
export const lowerCaseRegex = /^(?=.*[a-z])/
export const upperCaseRegex = /(?=.*[A-Z])/
export const numberRegex = /(?=.*\d)/
export const specialCharRegex = /(?=.*[@.#$!%*?&^])/
export const passwordlengthCheck = /^[A-Za-z\d@.#$!%*?&]{8,15}$/

export const passwordValidator = (password, confirmPassword) => {
    let passwordValidationErrors = []
    if(!lowerCaseRegex.test(password)) passwordValidationErrors.push(PASSWORD_VALIDATION.LOWERCASE)
    if(!upperCaseRegex.test(password)) passwordValidationErrors.push(PASSWORD_VALIDATION.UPPERCASE)
    if(!numberRegex.test(password)) passwordValidationErrors.push(PASSWORD_VALIDATION.NUMBER) 
    if(!specialCharRegex.test(password)) passwordValidationErrors.push(PASSWORD_VALIDATION.SPECIAL_CHAR)
    if(!passwordlengthCheck.test(password)) passwordValidationErrors.push(PASSWORD_VALIDATION.PW_LENGTH)
    if(password !== confirmPassword) passwordValidationErrors.push(PASSWORD_VALIDATION.NOT_MATCHED)
    return passwordValidationErrors
}

export const getDomainIdInfo = async (domainId, id) => {
    try {
        let config = {
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: 'same-origin'
        }
        const res = await authUrl.get(`/getAssociate/${domainId}?id=${id}`, config)
        const response = res.data
        return response
    } catch (error) {
        console.log(error)
    }
}

export const classNameGeneratorActiveAndDisabled = (name, active, disabled) => {
    let cssName = name
    if(active) cssName = cssName + ' active'
    if(disabled) cssName = cssName + ' disabled'
    return cssName
}

export const searchTypeIcon = (type) => {
    let icon
    switch (type) {
        case SPACE_TYPES_NAMES.CONFERENCE:
            icon = faUsersBetweenLines
            break;
        case SPACE_TYPES_NAMES.TRAINING:
            icon = faPersonChalkboard
            break;
        case SPACE_TYPES_NAMES.GYM:
            icon = faDumbbell
            break;
        case SPACE_TYPES_NAMES.SLEEPING_QUARTER:
            icon = faBed
            break;
        case SPACE_TYPES_NAMES.SEAT:
            icon = faComputer
            break;
        default:
            break;
    }
    return icon
}

export const getSiteDetails = (id, sites) => {
    const result = sites.find( ({siteId}) => siteId === id)
    return result
}

export const getSiteName = (id, sites) => {
    const result = sites?.find( site => site?._id === id)
    let name
    if (result?.name === undefined) name = 'Unable to load site information'
    else name = result?.name
    return name
}

export const getUTC = (ISOStringDate) => {
    const date = new Date(ISOStringDate)
    const UTCValue = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds())
    return UTCValue
}

export const toMilliseconds = (hrs,min,sec) => (hrs*60*60+min*60+sec)*1000;

export const padZeroes = (num, places) => {
    return String(num).padStart(2, '0')
} 

export const formatDateString = (pickedDate) => {
    let formattedDate = ''
    const pickerDate = new Date(pickedDate).getDate()
    const pickerYear = new Date(pickedDate).getFullYear()
    const pickerMonth = new Date(pickedDate).getMonth()
    formattedDate = `${pickerYear}-${padZeroes(Number(pickerMonth)+1, 2)}-${padZeroes(Number(pickerDate), 2)}`
    // console.log('formattedDate', formattedDate)
    return formattedDate
}
export const formatDateStringL = (pickedDate) => {
    let formattedDate = ''
    const pickerDate = new Date(pickedDate).getDate()
    const pickerYear = new Date(pickedDate).getFullYear()
    const pickerMonth = new Date(pickedDate).getMonth()
    formattedDate = `${padZeroes(Number(pickerMonth)+1, 2)}/${padZeroes(Number(pickerDate), 2)}/${pickerYear}`
    return formattedDate
}

export const isPastHour = (dateFormat, pickedDateString, hour) => {
    const nextHour = new Date(dateFormat).getHours() + 1
    const today = new Date(dateFormat).getDate()
    const month = new Date(dateFormat).getMonth() + 1
    const year = new Date(dateFormat).getFullYear()
    const todayDateFormat = new Date(`${month}/${today}/${year}`).getTime()
    const pickerDateFormat = new Date(pickedDateString).getTime()

    //logic
    // 1. if picked date is today, past hours must be disabled
    if (todayDateFormat === pickerDateFormat) {
        if (hour < nextHour) return true
        else return false
    }
    // 2. if picked date is in the future, all hours should be enabled
    if (pickerDateFormat > todayDateFormat) {
        return false
    }
}
export const isNextHour = (dateFormat, pickedDateStringL, hour) => {
    const nextHour = new Date(dateFormat).getHours() + 1
    if (nextHour === hour) return true
    else return false
    
}
export const convertFiltersToQueryParams = (filters) => {
    let queryParamsString = ''
    for (const [field, alias] of Object.entries(filters)) {
        queryParamsString = queryParamsString + `${field}=${alias}&`
    }
    const removeLastAmpersand = queryParamsString.toString().substring(0, queryParamsString.length-1)
    return removeLastAmpersand
}
export const isWithinCheckInWindow = (timeMillis_start) => {
    let response = false
    const timeNow = new Date()
    const differenceFromStartTime = timeNow.getTime() - timeMillis_start
    const differenceFromEndTime = (((timeMillis_start + (1000*60*(GLOBAL.CHECK_IN_WINDOW+1))) - timeNow.getTime())/(1000*60))
    response = (((Number(differenceFromStartTime) / (1000 * 60)) > -(GLOBAL.CHECK_IN_WINDOW)) && (differenceFromEndTime > 0))
    return response
}

export const getMomentDateTimeNow = (format) => {
    moment.tz.setDefault('Asia/Taipei')
    const nowMomentString = moment().tz('Asia/Taipei').format(format)
    return nowMomentString
}